<!-- eslint-disable camelcase -->
<template>
  <div>
    <div v-if="notFound">
      <b-card class="text-center">
        <feather-icon
          size="80"
          icon="SearchIcon"
        />
        <div class="error mt-2">
          <h2 class="my-2">
            Campaign is not found
          </h2>
          <b-button
            variant="outline-primary"
            @click="() => $router.push({name: 'campaigns'})"
          >
            Back to Campaigns
          </b-button>
        </div>
      </b-card>
    </div>
    <div v-else>
      <div
        v-if="loader"
        class="text-center mt-5"
      >
        <b-spinner
          type="grow"
          label="Loading..."
        />
      </div>
      <b-row v-else>
        <b-col
          cols="12"
          md="4"
        >
          <b-card>
            <div class="contact-box center-version">
              <div>

                <VueSlickCarousel
                  v-if="viewData.images.length>0"
                  v-bind="settings"
                >
                  <div
                    v-for="(image, index) in viewData.images"
                    :key="index"
                    class="conOfCampaignImgs"
                  >
                    <img
                      v-img
                      :src="image.url"
                      width="300"
                    >
                  </div>

                </VueSlickCarousel>
              </div>
              <div class="d-flex align-items-center flex-column">

                <h3 class="mb-2 mt-4">
                  <div
                    v-if="viewData.winners.length>0"
                    class="text-center"
                  >
                    <h3>Winner</h3>
                    <strong
                      v-for="winner in viewData.winners"
                      :key="winner.id"
                    >   <router-link :to="{ name: 'show-user-customer', params: { id: winner.user_id } }">
                      {{ winner.user_name }}
                    </router-link> <feather-icon
                      icon="GiftIcon"
                    /> {{ winner.gift_name }}</strong>
                  </div>

                  <strong v-else>Not Drawn Yet </strong>
                </h3>

              </div>
              <div class="product_gifts-info">
                <ul>
                  <li>
                    <feather-icon
                      icon="PackageIcon"
                    /> Products Number:  {{ viewData.products_names.length }}
                  </li>
                  <li>
                    <feather-icon
                      icon="GiftIcon"
                    /> Gift: {{ viewData.gift_name }}
                  </li>
                  <li v-if="viewData.limited==1">
                    <feather-icon
                      icon="ShoppingCartIcon"
                    /> Quantity : {{ viewData.quantity_limit }}
                  </li>
                  <li v-if="viewData.limited==0">
                    <feather-icon
                      icon="ShoppingCartIcon"
                    /> Quantity : Unlimited
                  </li>
                  <li>
                    <feather-icon
                      icon="AwardIcon"
                    /> Free Ticket Count: {{ viewData.free_ticket_count }}
                  </li>
                  <li>
                    <feather-icon
                      icon="AwardIcon"
                    /> Sold Ticket Count: {{ viewData.sold_ticket_count }}
                  </li>
                  <li>
                    <feather-icon
                      icon="AwardIcon"
                    /> Ticket Count: {{ viewData.ticket_count }}
                  </li>
                  <li>
                    <feather-icon
                      icon="UsersIcon"
                    /> Total Users Purchased: {{ viewData.total_user_bought_count }}
                  </li>
                  <li v-if="viewData.coming_soon_date">
                    <feather-icon
                      icon="CalendarIcon"
                    />Coming Soon Date: {{ viewData.coming_soon_date | formatDate }}
                  </li>
                  <li>
                    <feather-icon
                      icon="CalendarIcon"
                    />Draw Date: {{ viewData.draw_date | formatDate }}
                  </li>
                  <li>
                    <feather-icon
                      icon="BellIcon"
                    />Alert Before Days: {{ viewData.alert_before_days }}
                  </li>
                </ul>
              </div>
            </div>
          </b-card>

        </b-col>

        <b-col
          cols="12"
          md="8"
        >
          <div>
            <b-card no-body>
              <b-tabs card>
                <b-tab
                  title="Tickets"
                  active
                  class="pt-0"
                >
                  <b-col>
                    <div class="d-flex align-center justify-content-end mb-1"> <b-button
                                                                                 v-if="viewData.winners.length===0"
                                                                                 variant="secondary"
                                                                                 class="mx-1"
                                                                                 :to="{name: 'draw', params: {id: $route.params.id}}"
                                                                               >
                                                                                 <span class="text-nowrap">Live Draw </span>
                                                                               </b-button>
                      <b-button
                        v-if="viewData.winners.length===0"
                        variant="primary"
                        @click="randomDraw()"
                      >
                        <span class="text-nowrap">Random Draw </span>
                      </b-button></div>
                  </b-col>

                  <b-table
                    ref="TicketsTable"
                    :items="TicketsData"
                    :fields="columns"
                    aria-busy="true"
                    class="position-relative"
                    responsive
                    show-empty
                    bordered
                    primary-key="id"
                  >
                    <template #cell(order_id)="data">
                      <router-link
                        :to="{name: 'show-order', params: {id: data.item.order_id}}"
                      >  <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer order-view"
                      /> View Order</router-link>
                    </template>
                  </b-table>
                  <b-pagination
                    v-if="rows>0"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    align="end"
                    first-number
                    last-number
                    :total-visible="7"
                    class="mb-0 mt-1"
                    prev-class="prev-item"
                    next-class="next-item"
                    aria-controls="TicketsTable"
                    @click.native="getdata(currentPage)"
                  >

                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>

                </b-tab>
                <b-tab title="Products">
                  <div>
                    <b-table
                      ref="ProductsTable"
                      :items="ProductsData"
                      :fields="productsColumn"
                      aria-busy="true"
                      class="position-relative"
                      responsive
                      show-empty
                      bordered
                      primary-key="id"
                    >
                      <template v-slot:cell(name)="data">
                        <router-link :to="{ name: 'edit-product', params: { id: data.item.id } }">
                          {{ data.item.name }}
                        </router-link></template>
                      <template v-slot:cell(product_images)="data">
                        <b-img
                          v-if="data.item.product_images.length>0"
                          v-img
                          width="70"
                          :src="data.item.product_images[0].url"
                        />
                      </template>
                      <template v-slot:cell(price)="data">
                        <span :class="data.item.discount > 0 ? 'discounted-price' : ''">
                          {{
                            data.item.price
                          }}</span>
                        <sup
                          v-if="data.item.discount > 0"
                          class="conOfOffer"
                        >-{{ data.item.discount }}%</sup>
                        <span
                          v-if="data.item.discount> 0"
                          class="ml-1"
                        >{{ data.item.net_price }}</span>
                      </template>
                    </b-table>
                  </div>
                </b-tab>

              </b-tabs>
            </b-card>
          </div></b-col>

        <!-- campaignWinner Modal -->
        <div>
          <b-modal
            id="campaignWinner"
            ref="campaign-winner-modal"
            hide-footer
            centered
            title="Campaign's Winner"
          >
            <b-row>
              <b-col>
                <img
                  v-img
                  src="@/assets/images/other/couponWinnwerGif.gif"
                  class="winnerGif"
                >
              </b-col>
              <b-col
                cols="12"
                class=" d-flex justify-content-center"
              >
                <img
                  v-img
                  :src="viewData.image"
                  width="200"
                >
              </b-col>
              <b-col
                cols="12"
                class="text-center my-2"
              >
                <h2><span>Winner:</span>{{ winnerData.name }} </h2>   </b-col>

              <b-col
                cols="12"
                md="12"
                class="text-center"
              >
                <b-button
                  v-if="!loader"
                  variant="primary"
                  class="mr-1"
                  :disabled="disableUpdateStatusBtn"
                  @click="getdata"
                >
                  Ok
                </b-button>

              </b-col>
            </b-row>
          </b-modal>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import handleFormData from '@/global-compositions/handleFormData'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      settings: {
        rows: 1,
        dots: true,
        arrows: true,
        infinite: false,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,

      },
      totalRows: 0,
      perPage: 10,
      currentStep: 2,

    }
  },
  setup() {
    const viewData = ref({})
    // eslint-disable-next-line camelcase
    const product_giftsForm = ref({})
    const winnerData = ref({})

    const { formData, setFormData } = handleFormData()
    const columns = [
      { key: 'code', label: 'Code' },
      { key: 'user_name', label: 'User' },
      { key: 'gift_name', label: 'Gift' },
      { key: 'product_name', label: 'Product' },
      { key: 'voters_count', label: 'Voters' },
      { key: 'order_id', label: 'Order' },
      { key: 'is_free', label: 'Free' },
      { key: 'is_draw', label: 'Draw' },
    ]
    const productsColumn = [
      { key: 'product_images', label: 'Image' },
      { key: 'name', label: 'Name' },
      { key: 'price', label: 'Price' },
      { key: 'num_of_tickets', label: 'Num of Tickets' },
    ]
    const currentPage = ref(1)
    // eslint-disable-next-line camelcase
    const product_giftsAddresses = ref([])
    // eslint-disable-next-line camelcase
    const product_giftsFiles = ref([])
    const loader = ref(false)
    const notFound = ref(false)
    // eslint-disable-next-line camelcase
    const selected_active_type = ref('')

    const options = [
      { value: 'national_id', name: 'National ID' },
    ]
    const TicketsData = ''
    const ProductsData = ''

    const activeType = [
      { text: 'Accepted', value: 'Accepted' },
      { text: 'Rejected', value: 'Rejected' },
      { text: 'Pending', value: 'Pending' },
      { text: 'Hold', value: 'Hold' },
    ]

    return {
      winnerData,
      viewData,
      columns,
      currentPage,
      TicketsData,
      ProductsData,
      productsColumn,
      product_giftsAddresses,
      product_giftsFiles,
      options,
      loader,
      avatarText,
      notFound,
      activeType,
      product_giftsForm,
      selected_active_type,
      types: {},
      formData,
      setFormData,
    }
  },
  computed: {
    disableUpdateStatusBtn() {
      return this.selected_active_type === 'Rejected' && !this.product_giftsForm.rejection_reason
    },
    rows() {
      return this.totalRows
    },
  },

  created() {
    // eslint-disable-next-line radix
    const currentPage = parseInt(this.$route.query.page) || 1
    this.currentPage = currentPage
    this.getdata(this.currentPage)
  },
  methods: {
    filterTypes(type) {
      const filteredType = this.activeType.filter(item => item.text !== type)
      this.types = filteredType
    },

    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type == 'Active') return 'success'
      return 'danger'
    },
    getdata() {
      let skip
      if (this.currentPage !== 1) {
        skip = this.currentPage * 10 - 10
      } else {
        skip = 0
      }
      this.loader = true
      this.$bvModal.hide('campaignWinner')
      axios.get(`admin/product_gifts/${this.$route.params.id}?skip=${skip}`).then(res => {
        if (res.status === 200 || res.status === 201) {
          this.viewData = res.data?.data.product_gift
          this.TicketsData = res.data?.data.tickets
          this.ProductsData = res.data?.data.products_list
          this.totalRows = res.data.data.count
          this.$router.replace({ query: { ...this.$route.query, page: this.currentPage } }).catch(() => {})
        }
      }).catch(error => {
        if ((error.response.status === 400) || error.response.status === 404) {
          this.notFound = true
        }
      }).finally(() => {
        this.loader = false
      })
    },
    changeproduct_giftsType() {
      this.loader = true
      this.product_giftsForm.active_type = this.selected_active_type
      this.setFormData(this.product_giftsForm)
      this.formData.append('_method', 'PUT')
      axios.post(`product_gifts/${this.product_giftsForm.id}`, this.formData).then(res => {
        if (res.status === 200) {
          this.$bvModal.hide('campaignWinner')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      }).finally(() => {
        this.loader = false
      })
    },
    randomDraw() {
      this.$swal({
        title: 'Draw',
        text: 'Are you sure you want to make draw for this campaign?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Draw!',
      }).then(result => {
        if (result.isConfirmed) {
          axios.get(`admin/random_draw/${this.$route.params.id}`).then(res => {
            if (res.status === 200) {
              this.winnerData = res.data?.data.winner
              this.$bvModal.show('campaignWinner')
            }
          }).catch(error => {
            if (error.response.status === 500) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ooops!!! An Unexpected Internal server error',
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              })
            } else if (error.response.status === 422) {
              if (error.response.data.errors !== undefined) {
                this.errorMsg = error.response.data.errors
              } else {
                this.errorMsg = error.response.data.message
              } this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.errors,
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              })
            }

            this.getdata()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },

  },

}
</script>

<style lang="scss">
.conOfCampaignImgs{
  display: flex !important;
    align-items: center;
    justify-content: center;

}
.conOfStep{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step{
  width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border-radius: 50px;
    color: #f48a36;
    font-size: 20px;
    font-weight: bold;
}
.orangeLine {
  width: 3px;
  top: 100%;
  height: 40px;
  z-index: 1;
  position: absolute;
  background: #f48a36;
}
.winnerGif{
  width: 90%;
  height: 320px;
  position: absolute;

}
/* CONTACTS */
.contact-box {
  padding: 20px;
  margin-bottom: 20px;
}
.contact-box > a {
  color: inherit;
}
.contact-box.center-version {
  padding: 0;
}
.contact-box.center-version > .top {
  display: block;
  padding: 5px;
  text-align: center;
}
.contact-box.center-version > .top img {
  width: 80px;
  height: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.contact-box.center-version address {
  margin-bottom: 0;
}
.contact-box .contact-box-footer {
  border-top: 1px solid #e7eaec;
  padding: 15px 20px;
}
a{
    text-decoration:none !important;
}

.product_gifts-info ul {
  list-style: none;
  li {
    padding-top: 1rem;
  }
}
.change-status {
  cursor: pointer;
}
</style>
